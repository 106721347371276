import $ from 'jquery';

window.jQuery = $;
window.$ = $;

import 'animate.css/animate.min.css';
import 'owl.carousel/dist/assets/owl.carousel.css';
import Request from "../../js/components/request";

let owlCarousel = require('owl.carousel');
window.fn = owlCarousel;

$(document).ready(function(){
    new iFacade();
});

$(document).on('click', '.auth', function (e) {
    e.preventDefault();
    $('#auth').addClass('--open');
    $('#reg').removeClass('--open');

    $('body').css({'overflow': 'hidden'});
});

$(document).on('click', '#auth', function (e) {
    if (!$(e.target).closest('.content-form__wrapper').length) {
        e.preventDefault();
        $('#auth').removeClass('--open');
        $('body').css({'overflow': 'initial'});
    }
});

$(document).on('click', '.reg', function (e) {
    e.preventDefault();
    $('#auth').removeClass('--open');
    $('#reg').addClass('--open');

    $('body').css({'overflow': 'hidden'});
});

$(document).on('click', '#reg', function (e) {
    if (!$(e.target).closest('.content-form__wrapper').length) {
        e.preventDefault();
        $('#reg').removeClass('--open');
        $('body').css({'overflow': 'initial'});
    }
});

$(document).on('click', '.map', function (e) {
    e.preventDefault();
    $('#map').addClass('--open');
});

$(document).on('click', '#map', function (e) {
    e.preventDefault();
    if (!$(e.target).closest('ymaps').length) {
        $('#map').removeClass('--open');
    }
});

var iFacade = function(){
    let self  = this;
    self._bindVars();
    self._bindUI();
};

$.extend(iFacade.prototype, {
    _elems : {
        $preloader : $(),
        $header    : $(),
        $toolbar   : $(),
        $help      : $(),
        $advntg    : $(),
        $widgets   : $()
    },
    _state : {
        $ajax : null
    },
    _bindVars: function () {
        let self = this;

        self._elems.$preloader  = $('.preloader');
        self._elems.$header     = $('.header');
        self._elems.$nav        = $('.header-nav');
        self._elems.$toolbar    = $('.header-toolbar');
        self._elems.$help       = $('.section-help');
        self._elems.$advntg     = $('.section-advntg');
        self._elems.$widgets    = $('.section-widgets');

        self._elems.$auth       = $('#form-auth')
        self._elems.$reg        = $('#form-reg')
    },
    _bindUI : function () {
        let self = this;

        self._initLoader();
        self._initToolbar();
        self._initNav();
        self._initHelp();
        self._initAdvntg();
        self._initWidgets();
        self._initAuth();
        self._initReg();
    },
    _initLoader : function () {
        let self = this;

        if (!self._elems.$preloader.length) return;

        self._elems.$preloader.on('show', function(){
            self._elems.$preloader.stop().fadeIn(777);
        });

        self._elems.$preloader.on('hide', function(){
            self._elems.$preloader.stop().fadeOut(777);
        });

        self._elems.$preloader.trigger('hide');
    },
    _initToolbar : function () {
        let self = this;

        if (!self._elems.$toolbar.length) return;

        self._elems.$toolbar.click(function(e){
            e.preventDefault();

            $(this).toggleClass('--active');

            if (!self._elems.$nav.length) return;
            self._elems.$nav.toggleClass('--active');

            if ($(this).hasClass('--active')) {
                $('body').css({'overflow': 'hidden'});
            }
            else {
                $('body').css({'overflow': 'initial'});
            }
        });
    },
    _initNav : function() {
        let self = this;

        if (!self._elems.$nav.length) return;

        self._elems.$nav.find('a[href^="#"]').click(function(e){
            e.preventDefault();

            self._elems.$nav.removeClass('--active');
            $('body').css({'overflow': 'initial'});
            if (self._elems.$toolbar.length) {
                self._elems.$toolbar.removeClass('--active');
            }

            let $target = $('[data-name="' + $(this).attr('href').split('#')[1] + '"]');
            if (!$target.length) return;

            $('html, body').animate({
                scrollTop: $target.offset().top
            }, 500);
        });
    },
    _initHelp : function () {
        let self = this;

        if (!self._elems.$help.length) return;

        let $tabs = self._elems.$help.find('.section-tabs');
        if (!$tabs.length) return;

        let $pains = self._elems.$help.find('.section-pains');

        $tabs.find('a').click(function(e){
            e.preventDefault();

            let $a = $(this);

            $tabs.find('a').removeClass('--active');
            $a.addClass('--active');

            if (!$pains.length) return;

            $pains.children().removeClass('--active');
            $pains.children().eq($a.parent().index()).addClass('--active');
        });
    },
    _initAdvntg : function () {
        let self = this;

        if (!self._elems.$advntg.length) return;

        let $tables = self._elems.$advntg.find('.section-advntg-tables');
        if (!$tables.length) return;

        let $titles = self._elems.$advntg.find('.section-advntg-titles');

        let owl = null;
        let init = function () {
            owl = $tables.owlCarousel({
                items      : 1,
                animateOut : 'fadeOut',
                animateIn  : 'fadeIn',
                autoHeight : true
            });
            $tables.addClass('owl-carousel');

            if (!$titles.length) return;
            owl.on('changed.owl.carousel', function(e){
                const index = e.item.index;
                $titles.children().removeClass('--active');
                let $title = $titles.children().eq(index);
                if (!$title.length) return;
                $title.addClass('--active');
            });
        };

        if ($(window).width() < 768) {
            init();
            $titles.children().eq(0).addClass('--active');
        }

        $(window).resize(function(){
            if ($(window).width() < 768) {
                owl === null && init();
            } else if (owl !== null) {
                owl.trigger('destroy.owl.carousel');
                $tables.removeClass('owl-carousel');
                owl = null;
            }

        })
    },
    _initWidgets : function () {
        let self = this;

        if (!self._elems.$widgets.length) return;

        let $carousel = self._elems.$widgets.children('.owl-carousel');
        if (!$carousel.length) return;

        let owl = $carousel.owlCarousel({
            items      : 1,
            animateOut : 'fadeOut',
            animateIn  : 'fadeIn'
        });

        let $title = self._elems.$widgets.find('.section-title');
        let $link = self._elems.$widgets.find('.btn.--base');

        owl.on('changed.owl.carousel', function(e){
            const index = e.item.index;
            let $slide = $carousel.find('.owl-item').eq(index).children();
            if (!$slide.length) return;

            if (!$title.length) return;
            $title.text($slide.data('name'));

            $('.section-widgets-nav__index').text('0' + (index + 1));

            $link.attr('href', $slide.find('.btn').attr('href'));
        });

        let $next = self._elems.$widgets.find('.section-widgets-nav__next');
        if (!$next.length) return;

        $next.click(function(e){
            e.preventDefault();
           owl.trigger('next.owl.carousel');
        });

        let $prev = self._elems.$widgets.find('.section-widgets-nav__prev');
        if (!$prev.length) return;

        $prev.click(function(e){
            e.preventDefault();
            owl.trigger('prev.owl.carousel');
        });
    },
    _callbackAuthSubmit(response) {
        let self = this

        if (response.error || response.errors) {
            let error = response.error ? response.error : response.errors
            if (error.type == 'validation') {
                self._elems.$auth.find('.form-field[data-name]').each(function() {
                    $(this).removeClass('error');
                    $(this).find('.form-field__message').hide().addClass('hidden');
                });

                Object.keys(error.fields).forEach(function(fieldName) {
                    self._elems.$auth.find('.form-field[data-name=' + fieldName.replace(/\./g, '\\.')  + '], .form-field__wrapper[data-name=' + fieldName.replace(/\./g, '\\.')  + ']').each(function() {
                        let $field = $(this);
                        $field.addClass('error');

                        if (!$field.find('.form-field__message').length) {
                            $field.append(
                                $('<div />')
                                    .addClass('form-field__message')
                            )
                        }
                        $field.find('.form-field__message').each(function() {
                            $(this).removeClass('hidden').show().html(error.fields[fieldName].join('<br />'));
                        });
                    });
                });

                let firstFieldName = Object.keys(error.fields)[0].replace(/\./g, '\\.');
                let $first = self._elems.$auth.find('.form-field[data-name=' + firstFieldName + ']').find('input, select');
                $first.focus();
            }
            else if (error.type == 'auth') {
                $('#auth').find('.content-form__error').text(error.text).show();
            }

            return
        }

        window.location.href = '/profile'
    },
    _bindAuthSubmit () {
        let self = this

        self._elems.$preloader.trigger('show')

        $.ajax({
            url     : '/login',
            method  : 'POST',
            data    : self._elems.$auth.serialize(),
            success : (response) => {
                self._elems.$preloader.trigger('hide')
                self._callbackAuthSubmit(response)
            },
            error   : (xhr, status, response) => {
                self._elems.$preloader.trigger('hide')
                self._callbackAuthSubmit(xhr.responseJSON)
            }
        })
    },
    _initAuth () {
        let self = this;

        if (!self._elems.$auth.length) return;

        self._elems.$auth.on('submit', function(e){
            e.preventDefault()
            self._bindAuthSubmit()
        })

        self._elems.$auth.find('input').keyup(function(){
            self._elems.$auth.find('.form-field[data-name]').each(function() {
                $(this).removeClass('error');
                $(this).find('.form-field__message').hide().addClass('hidden');
            });
            $('#auth').find('.content-form__error').text('').hide();
        })
    },

    _callbackRegSubmit(response) {
        let self = this;

        if (response.error || response.errors) {
            let error = response.error ? response.error : response.errors
            if (error.type == 'validation') {
                self._elems.$reg.find('.form-field[data-name]').each(function() {
                    $(this).removeClass('error');
                    $(this).find('.form-field__message').hide().addClass('hidden');
                });

                Object.keys(error.fields).forEach(function(fieldName) {
                    self._elems.$reg.find('.form-field[data-name=' + fieldName.replace(/\./g, '\\.')  + '], .form-field__wrapper[data-name=' + fieldName.replace(/\./g, '\\.')  + ']').each(function() {
                        let $field = $(this);
                        $field.addClass('error');

                        if (!$field.find('.form-field__message').length) {
                            $field.append(
                                $('<div />')
                                    .addClass('form-field__message')
                            )
                        }
                        $field.find('.form-field__message').each(function() {
                            $(this).removeClass('hidden').show().html(error.fields[fieldName].join('<br />'));
                        });
                    });
                });

                let firstFieldName = Object.keys(error.fields)[0].replace(/\./g, '\\.');
                let $first = self._elems.$reg.find('.form-field[data-name=' + firstFieldName + ']').find('input, select');
                $first.focus();
            }

            return
        }

        window.location.href = '/profile'
    },

    _bindRegSubmit () {
        let self = this

        self._elems.$preloader.trigger('show')

        $.ajax({
            url     : '/registration',
            method  : 'POST',
            data    : self._elems.$reg.serialize(),
            success : (response) => {
                self._elems.$preloader.trigger('hide')
                self._callbackRegSubmit(response)
            },
            error   : (xhr, status, response) => {
                self._elems.$preloader.trigger('hide')
                self._callbackRegSubmit(xhr.responseJSON)
            }
        })
    },
    _initReg () {
        let self = this;

        if (!self._elems.$reg.length) return;

        self._elems.$reg.on('submit', function(e){
            e.preventDefault()
            self._bindRegSubmit()
        })

        self._elems.$reg.find('input').keyup(function(){
            self._elems.$reg.find('.form-field[data-name]').each(function() {
                $(this).removeClass('error');
                $(this).find('.form-field__message').hide().addClass('hidden');
            });
            $('#reg').find('.content-form__error').text('').hide();
        })
    }
});