export default {
    send : function(args) {
        let self = this;

        if (!args.self_flow && xhr) {
            xhr.abort();
        }

        if (args.queue) {
            let responses = [];

            let process = function(request_index) {
                return self.send({
                    path   : args.queue[request_index].path,
                    method : args.queue[request_index].method,
                    data   : args.queue[request_index].data,
                    done   : function(response) {
                        responses.push(response);

                        if (request_index + 1 < args.queue.length) {
                            return process(request_index + 1);
                        }

                        return args.done && args.done.apply(null, responses);
                    }
                });
            };

            process(0);
            return;
        }

        let data = '';
        if(typeof args.data === 'object'){
            for(let prop in args.data)
                if(typeof args.data[prop] === 'object'){
                    for(let prop2 in args.data[prop])
                        data += '&' + prop + '[' + prop2 + ']=' + args.data[prop][prop2];
                }
                else
                    data += '&' + prop + '=' + args.data[prop];
        }
        data += (args.data) ? ((typeof args.data === 'object') ? '' : args.data) : '';
        data += (data != '' && window.location.pathname.indexOf('/sandbox') != -1) ? '&' : '';
        data += (window.location.pathname.indexOf('/sandbox') !== -1) ? 'mode=sandbox' : '';

        data += (data != '' && window.location.pathname.indexOf('/profile') != -1) ? '&' : '';
        data += (window.location.pathname.indexOf('/profile') != -1) ? 'mode=profile' : '';

        if (args.url)
            data += '&url=' + args.url;

        let url = args.path;
        if(window.auth !== undefined && auth && auth.user && args.path.indexOf('api_token') == -1){
            if(args.path.indexOf('?') == -1)
                url += '?';
            else
                url += '&';

            url += 'api_token=' + auth.user.api_token;
        }

        let xhr = $.ajax({
            url    : url,
            method : args.method,
            data   : data,

            beforeSend : function(request) {
                if (window.tip_window) {
                    window.tip_window.close();
                    window.tip_window = null;
                }

                if (window.csrf_token !== undefined)
                    return request.setRequestHeader('X-CSRF-Token', csrf_token);
            }
        }).done(function(response) {
            args.done && args.done(response);

            if (!args.self_flow) {
                window.xhr = null;
            }
        }).fail(function(event) {
            if (event.statusText == 'abort') {
                if (!args.self_flow)
                    window.xhr = null;
                return;
            }

            if (event.status == 422 && args.validate) {
                args.validate(event.responseJSON);
                return;
            }

            if (args.fail) {
                args.fail();
                return;
            }

            if(args.page){
                let title = 'Ошибка';
                if (event.status == 403) {
                    title = 'Ошибка. Доступ закрыт';
                } else if(event.status == 404) {
                    title = 'Ошибка. Страница не найдена';
                }

                $('.content').html('<div class="ifacade__title"><h1>' + title + '</h1><div>');
                return;
            }

            event.responseJSON && args.done && args.done(event.responseJSON);

            if (!args.self_flow) {
                window.xhr = null;
            }
        });

        if (!args.self_flow) {
            window.xhr = xhr;
        }

        return xhr;
    }
};
